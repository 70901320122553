<template>
  <div class="contacts">
    <Title label="Контакты" border />
    <template v-for="item in projectSettings" :key="item.id">
      <div
        class="contacts__block"
        v-if="item.settings.responsible.data.length > 0"
      >
        <div class="contacts__block-title">{{ item.title }}</div>
        <div class="contacts__header">
          <div class="contacts__responls">Ответственный</div>
          <div class="contacts__name">Должность</div>
          <div class="contacts__media">Социальные сети</div>
          <div class="contacts__number">Номер телефона</div>
        </div>
        <div class="contacts__status">
          <ContactsStatus
            v-for="(contact, index) in item.settings.responsible.data"
            :key="index"
            :data="contact"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ContactsStatus from "@/components/ContactsSection/ContactsStatus";
import Title from "@/components/Blocks/Title";
import { mapGetters } from "vuex";

export default {
  name: "Contacts",
  components: { Title, ContactsStatus },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["projectSettings"]),
  },
  watch: {
    sortedArray: {
      handler: function () {},
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.contacts {
  &__title {
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
    @include adaptive(tablet-big) {
      padding-bottom: 15px;
    }
    @include adaptive(phone) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__block {
    margin-top: 40px;
    &-title {
      margin-bottom: 20px;
      font-size: 3rem;
      line-height: 3.6rem;
      font-weight: 500;
    }
  }
  &__header {
    display: grid;
    grid-template-columns: 300px minmax(230px, 400px) 180px minmax(200px, 1fr);
    font-weight: 600;
    line-height: 2rem;
    border: 1px solid var(--background--btn--profile--hover);
    & > div {
      padding: 16px;
      &:not(:last-child) {
        border-right: 1px solid var(--background--btn--profile--hover);
      }
    }
  }
  &__name,
  &__respons,
  &__media,
  &__number {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    line-height: 2rem;
    white-space: nowrap;
  }
  @media (max-width: 1280px) {
    &__status {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &__header {
      display: none;
    }
  }
  &__status {
    @include adaptive(phone) {
      display: block;
    }
  }
  &__filter {
    &::after {
      transform: rotate(180deg);
    }
  }
}
</style>
