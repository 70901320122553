<template>
  <div class="contacts-status">
    <div class="contacts-status__respons">
      <p>Ответственный</p>
      <div class="contacts-status__respons--img">
        <img :src="data.avatar ?? '@/assets/Person.svg'" alt="avatar" />
      </div>
      <span>
        {{ data.fio }}
      </span>
    </div>
    <div class="contacts-status__name">
      <p>Должность</p>
      <div class="contacts-status__name-list">
        <span v-for="(item, index) in data.info_positions.data" :key="index">
          {{ item.title }}
        </span>
      </div>
    </div>
    <div class="contacts-status__media">
      <a
        :href="item.href"
        v-for="(item, index) in data.socials.data"
        :key="index"
        target="_blank"
      >
        <Icon :type="item.type" />
      </a>
    </div>
    <div class="contacts-status__number">
      <p>Номер телефона</p>
      <input
        type="tel"
        readonly
        :value="data.phone"
        v-maska="'+# (###) ###-##-##'"
      />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import { maska } from "maska";
export default {
  directives: { maska },
  components: { Icon },
  props: ["data"],
};
</script>

<style lang="scss">
.contacts-status {
  display: grid;
  grid-template-columns: 300px minmax(230px, 400px) 180px minmax(200px, 1fr);
  border-color: var(--background--btn--profile--hover);
  border-style: solid;
  border-width: 0 1px 1px 1px;
  & > div {
    padding: 16px;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid var(--background--btn--profile--hover);
    }
  }

  & > div > p {
    display: none;
  }
  &__number {
    white-space: nowrap;
    input {
      outline: none;
      border: none;
    }
  }
  &__respons {
    gap: 10px;
    overflow-x: auto;
    @include scrollbar();
    &--img {
      position: relative;
      max-width: 60px;
      flex-shrink: 0;
      width: 100%;
      height: 60px;
      border-radius: 50%;
      border: 1px solid var(--color--disabled);
      & img {
        @include full-absolute();
        object-fit: scale-down;
        border-radius: 50%;
      }
    }
  }
  &__name {
    &-list {
      display: flex;
      flex-direction: column;
      grid-gap: 3px;
    }
  }
  @include adaptive(tablet-small) {
    padding-left: 0;
  }

  &__media {
    justify-content: center;
    & i {
      margin-right: 10px;
      font-size: 2.4rem;
      color: var(--text--color);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: #00bfff;
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;

    border-width: 0 0 1px 0;
    align-items: flex-start;
    .contacts-status__media {
      order: 1;
    }
    & > div {
      border: none;
      display: block;
      padding-left: 0;
      &:not(:last-child) {
        border-right: none;
      }
      & p {
        display: block;
        margin-bottom: 8px;
        font-weight: 600;
      }
    }
    &__respons--img {
      margin-bottom: 8px;
    }
  }
}
</style>
